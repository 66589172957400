import React from "react";
import Head from "./Head";
import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";
import "./Layout.scss";

const Layout = (props) => {
  return (
    <>
      <Head title="DLSIM" />
      <Header />
      <main className="steppage">
        <div className="container">
          <section className="section">{props.children}</section>
          <Navigation
            step={props.step}
            returnHref={props.returnHref}
            nextHref={props.nextHref}
            returnDataColor={props.returnDataColor}
            nextDataColor={props.nextDataColor}
            dataCheck01={props.dataCheck01}
            dataCheck02={props.dataCheck02}
            dataCheck03={props.dataCheck03}
            dataCheck04={props.dataCheck04}
          />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
