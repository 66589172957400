import React from "react";
import "./Bar.scss";

const Bar = (props) => {
  return (
    <div className="section-bar">
      <span>{props.number}</span>
      <p>{props.text}</p>
    </div>
  );
};

export default Bar;
