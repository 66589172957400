import React from "react";
import "./Step.scss";
import Bar from "../components/Bar";
import Layout from "../components/Layout";
import ImgStep0101 from "../images/step0101.jpg";
import ImgStep0102 from "../images/step0102.jpg";

// Path
const homePath = "/";
const step02Path = "/Step02";

const Step01Page = () => {
  return (
    <Layout
      step="1"
      returnHref={homePath}
      nextHref={step02Path}
      returnDataColor="border"
      nextDataColor="primary"
      dataCheck01="wip"
      dataCheck02=""
      dataCheck03=""
      dataCheck04=""
    >
      <Bar number="1" text="使いたいデスクの種類を選択してください" />

      <div className="section-lead">
        <p>作業時の姿勢や目的、部屋のスペースに応じて選ぶのがおすすめです。</p>
      </div>

      <div className="section-inner">
        <div className="card">
          <span className="card-title">ハイデスク</span>
          <p className="card-lead">
            通常の高さで、デスクチェアなど座って使用するデスクです。
          </p>
          <div className="card-img">
            <img src={ImgStep0101} alt="sample" />
          </div>
          <ul className="card-feature">
            <li>特徴</li>
            <li>ハイデスクの特徴やメリット・デメリット</li>
            <li>ハイデスクの特徴やメリット・デメリット</li>
            <li>ハイデスクの特徴やメリット・デメリット</li>
            <li>ハイデスクの特徴やメリット・デメリット</li>
            <li>ハイデスクの特徴やメリット・デメリット</li>
          </ul>
        </div>

        <div className="card">
          <span className="card-title">ローデスク</span>
          <p className="card-lead">
            高さが低く、床や座椅子に座って使用するデスクです。
          </p>
          <div className="card-img">
            <img src={ImgStep0102} alt="sample" />
          </div>
          <ul className="card-feature">
            <li>特徴</li>
            <li>ローデスクの特徴やメリット・デメリット</li>
            <li>ローデスクの特徴やメリット・デメリット</li>
            <li>ローデスクの特徴やメリット・デメリット</li>
            <li>ローデスクの特徴やメリット・デメリット</li>
          </ul>
        </div>
      </div>
      <div className="section-btn">
        <input type="radio" name="radio" id="radio1" />
        <label for="radio1">ハイデスク</label>
        <input type="radio" name="radio" id="radio2" />
        <label for="radio2">ローデスク</label>
        <p>種類を選択し、「次へ」を押してください。</p>
      </div>
    </Layout>
  );
};

export default Step01Page;
