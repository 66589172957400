import React from "react";
import "./Navigation.scss";
import Button from "./Button";

const Navigation = (props) => {
  return (
    <nav className="nav">
      <span className="nav-number">ステップ： {props.step} / 4</span>
      <ul className="nav-list">
        <li>
          <span className="material-icons" data-check={props.dataCheck01}>
            check_circle_outline
          </span>
          <span>ステップ１</span>
        </li>
        <li>
          <span className="material-icons" data-check={props.dataCheck02}>
            check_circle_outline
          </span>
          <span>ステップ２</span>
        </li>
        <li>
          <span className="material-icons" data-check={props.dataCheck03}>
            check_circle_outline
          </span>
          <span>ステップ３</span>
        </li>
        <li>
          <span className="material-icons" data-check={props.dataCheck04}>
            check_circle_outline
          </span>
          <span>ステップ４</span>
        </li>
      </ul>
      <div className="nav-move">
        <Button
          href={props.returnHref}
          dataColor={props.returnDataColor}
          dataForm="square"
        >
          <span className="material-icons return">chevron_left</span>
          <span>戻る</span>
        </Button>
        <Button
          href={props.nextHref}
          dataColor={props.nextDataColor}
          dataForm="square"
        >
          <span>次へ</span>
          <span className="material-icons next">chevron_right</span>
        </Button>
      </div>
    </nav>
  );
};

export default Navigation;
